import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { setInspectionTaskDetails } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";

interface IAddModal {
  header: string;
  showModal: boolean;
  setShowModal: (state) => void;
  children: React.ReactNode;
  style?: object;
  OnSaveClick?: Function | any;
  isAdd?: boolean;
  customButtons?: any;
  onCloseModal?: Function | any;
  showAdd?: boolean;
  loader?: boolean;
}

export const AddModal: React.FC<IAddModal> = (props) => {
  const {
    header = "",
    showModal = false,
    setShowModal = () => { },
    children = null,
    style = {},
    OnSaveClick,
    isAdd = true,
    showAdd = true,
    loader = false,
  } = props;

  const dispatch = useDispatch<any>();

  const renderFooter = () => {
    if (props.customButtons) {
      return props.customButtons;
    }
    return (
      <div>
        <Button
          label="Cancel"
          className="cancel-btn"
          onClick={() => hideDialogHandler()}
        />
        {showAdd &&
          <Button
            label={isAdd ? "Save" : "Save Changes"}
            className="save-btn"
            disabled={loader}
            onClick={OnSaveClick}
          />
        }
      </div>
    );
  };

  const hideDialogHandler = () => {
    dispatch(setInspectionTaskDetails([]));
    setShowModal(false);
    props.onCloseModal && props.onCloseModal();
  }

  return (
    <Dialog
      header={header}
      visible={showModal}
      onHide={() => hideDialogHandler()}
      breakpoints={{ "960px": "75vw" }}
      style={style}
      footer={renderFooter()}
      className="add-overlay"
    >
      {children}
    </Dialog>
  );
};

export default AddModal;
